import React from "react";

const AdminProductDashboard = () => {
  return (
    <div>
      <h2>Products Dashboard</h2>
      <h3>As An admin you can modify products, use the navigation above</h3>
      <p>
        Someday this will be proper dashboard.......
      </p>
    </div>
  );
};

export default AdminProductDashboard;
